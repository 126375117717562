import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Dockerfile with PHP and NodeJS`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-dockerfile",
        "metastring": "path=Dockerfile",
        "path": "Dockerfile"
      }}>{`FROM node:\${NODE_VERSION:-17.7.2}-alpine AS node
FROM wodby/php:\${PHP_VERSION:-8.1-4.28.4}

COPY --from=node /usr/local/lib/node_modules/ /usr/local/lib/node_modules/
COPY --from=node /usr/local/bin/node /usr/local/bin/

RUN ln -s /usr/local/lib/node_modules/npm/bin/npm-cli.js /usr/local/bin/npm
RUN ln -s /usr/local/lib/node_modules/npm/bin/npx-cli.js /usr/local/bin/npx

EXPOSE 3000 9000
`}</code></pre>
    <h2>{`Docker Compose`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=docker-compose.yml",
        "path": "docker-compose.yml"
      }}>{`version: "3.8"

services:
  app:
    build: .
    image: app
    ports:
      - 9000
    volumes:
      - app-volume:/var/www/html
    networks:
      - default
    depends_on:
      - websockets
      - scheduler
      - worker
      - mariadb
      - minio
      - redis
      - meilisearch

  nginx:
    image: wodby/nginx:1.19-5.15.0
    ports:
      - \${FORWARD_NGINX_PORT:-80}:80
    environment:
      NGINX_VHOST_PRESET: \${NGINX_VHOST_PRESET:-php}
      NGINX_BACKEND_HOST: \${NGINX_BACKEND_HOST:-app}
      NGINX_SERVER_ROOT: \${NGINX_SERVER_ROOT:-/var/www/html/public}
    volumes:
      - app-volume:/var/www/html
    networks:
      - default
    depends_on:
      - app

  websockets:
    build: .
    image: app
    ports:
      - \${FORWARD_WEBSOCKETS_PORT:-6001}:6001
    volumes:
      - app-volume:/var/www/html
    networks:
      - default
    command: php artisan websockets:serve

  scheduler:
    build: .
    image: app
    volumes:
      - app-volume:/var/www/html
    networks:
      - default
    command: watch -tn 60 php artisan schedule:run

  worker:
    build: .
    image: app
    volumes:
      - app-volume:/var/www/html
    networks:
      - default
    depends_on:
      - redis
    command: php artisan queue:work --verbose --tries=3 --timeout=60

  mariadb:
    image: mariadb:10
    ports:
      - \${FORWARD_DB_PORT:-3306}:3306
    environment:
      MYSQL_ROOT_PASSWORD: \${DB_PASSWORD}
      MYSQL_DATABASE: \${DB_DATABASE}
      MYSQL_USER: \${DB_USERNAME}
      MYSQL_PASSWORD: \${DB_PASSWORD}
      MYSQL_ALLOW_EMPTY_PASSWORD: yes
    volumes:
      - mariadb-volume:/var/lib/mysql
    networks:
      - default
    healthcheck:
      test: [CMD, mysqladmin, ping, "-p\${DB_PASSWORD}"]
      retries: 3
      timeout: 5s

  minio:
    image: minio/minio:latest
    ports:
      - \${FORWARD_MINIO_PORT:-9000}:9000
      - \${FORWARD_MINIO_CONSOLE_PORT:-8900}:8900
    environment:
      MINIO_ROOT_USER: default
      MINIO_ROOT_PASSWORD: password
    volumes:
      - minio-volume:/data/minio
    networks:
      - default
    command: minio server /data/minio --console-address :8900
    healthcheck:
      test: [CMD, curl, -f, http://localhost:9000/minio/health/live]
      retries: 3
      timeout: 5s

  redis:
    image: redis:alpine
    ports:
      - \${FORWARD_REDIS_PORT:-6379}:6379
    volumes:
      - redis-volume:/data
    networks:
      - default
    healthcheck:
      test: [CMD, redis-cli, ping]
      retries: 3
      timeout: 5s

  meilisearch:
    image: getmeili/meilisearch:v0.20.0
    ports:
      - \${FORWARD_MEILISEARCH_PORT:-7700}:7700
    environment:
      MEILI_ENV: \${MEILI_ENV:-production}
      MEILI_NO_ANALYTICS: true
    volumes:
      - meilisearch-volume:/data.ms
    networks:
      - default
    healthcheck:
      test: [CMD, wget, --no-verbose, --spider,  http://localhost:7700/health]
      retries: 3
      timeout: 5s

  mailhog:
    image: mailhog/mailhog:latest
    ports:
      - 1025
      - \${FORWARD_MAILHOG_DASHBOARD_PORT:-8025}:8025
    networks:
      - default

networks:
  default:
    driver: bridge

volumes:
  app-volume:
    driver: local
    driver_opts:
      o: bind
      type: none
      device: \${PWD}
    labels:
      kompose.volume.size: 1G
  mariadb-volume:
    driver: local
  minio-volume:
    driver: local
  redis-volume:
    driver: local
  meilisearch-volume:
    driver: local
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      